<template>
  <b-row>
    <b-col md="4">
      <b-card>
        <b-form @submit.prevent>
          <b-row>
            <b-col cols="12">
              <b-form-group label="Сумма сделки" label-for="value">
                <b-form-input
                  id="value"
                  v-model="inputVal.value"
                  placeholder="0.00"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group label="Срок (мес.)" label-for="period">
                <b-form-input
                  id="period"
                  v-model="inputVal.period"
                  placeholder="0"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group label="Ставка (годовая)" label-for="rate">
                <b-form-input
                  id="rate"
                  v-model="inputVal.rate"
                  type="text"
                  placeholder="0"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group label="Номер договора" label-for="document_number">
                <b-form-input
                  id="document_number"
                  v-model="inputVal.document_number"
                  placeholder=""
                />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group label="IBAN" label-for="bank_account">
                <b-form-input
                  id="bank_account"
                  v-model="inputVal.bank_account"
                  placeholder=""
                />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group
                label="Дата договора"
                label-for="date_document_start"
              >
                <b-form-input
                  id="date_document_start"
                  v-model="inputVal.date_document_start"
                  placeholder=""
                />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group
                label="Дата зачисления денег"
                label-for="date_payment_start"
              >
                <b-form-input
                  id="date_payment_start"
                  v-model="inputVal.date_payment_start"
                  placeholder=""
                />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <div>
                <b-row>
                  <b-col>
                    <b-button
                      variant="primary"
                      class="mr-1"
                      @click="$emit('updateDeal')"
                    >
                      Обновить
                    </b-button>
                  </b-col>
                  <b-col>
                    <b-button variant="secondary" @click="updateSchedule">
                      График
                    </b-button>
                  </b-col>
                  <b-col>
                    <b-form-radio-group
                      id="radio-selector"
                      v-model="day_no"
                      :options="radioOptions"
                      name="radio-options"
                      button-variant="secondary"
                      size="sm"
                      stacked="True"
                    ></b-form-radio-group>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </b-col>
    <b-col md="8">
      <b-card>
        <h4>Предварительный график</h4>
        <b-table
          :items="schedule"
          :fields="fields"
          show-empty
          empty-text="Нет данных"
          responsive
        />
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: ['value'],
  data() {
    return {
      schedule: [],
      radioOptions: [
        { text: '10', value: 10 },
        { text: '25', value: 25 },
      ],
      day_no: 10,
      fields: [
        {
          key: 'payment_order',
          label: 'Порядок платежа',
        },
        {
          key: 'date',
          label: 'Дата',
        },
        {
          key: 'balance_amount',
          label: 'Основной Долг',
        },
        {
          key: 'percent_amount',
          label: 'Проценты',
        },
        {
          key: 'amount_to_pay',
          label: 'Общая Сумма Платежа',
        },
      ],
    }
  },
  computed: {
    inputVal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  created() {
    if (this.inputVal.id) {
      this.getSchedule()
    }
  },
  methods: {
    async getSchedule() {
      const res = await this.$http.get(
        `admin/deal/schedule/?deal=${this.inputVal.id}`
      )
      this.schedule = res.data.results
      this.schedule.push({
        payment_order: 'Итого',
        date: '',
        balance_amount: this.inputVal.total_schedule_balance,
        percent_amount: this.inputVal.total_schedule_percent,
        amount_to_pay: this.inputVal.total_schedule_amount,
      })
    },
    async updateSchedule() {
      try {
        const res = await this.$http.get(
          `admin/deal/${this.inputVal.id}/schedule/${this.day_no}/`
        )
        this.schedule = res.data
        await this.$emit('getDeal')
        this.schedule.push({
          payment_order: 'Итого',
          date: '',
          balance_amount: this.inputVal.total_schedule_balance,
          percent_amount: this.inputVal.total_schedule_percent,
          amount_to_pay: this.inputVal.total_schedule_amount,
        })
        this.$toast.success('Обновлено')
      } catch (e) {
        this.$toast.danger('Ошибка')
      }
    },
  },
}
</script>